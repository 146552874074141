import React from "react"
import { graphql } from "gatsby"
import Link from "gatsby-link"
import SEO from "../components/seo"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Faq from "../components/Faq"
import PAGEDATA from "../../content/pricing.json"
import PageWrapper from "../PageWrapper"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import {Helmet} from "react-helmet";

const PricingIndex = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <PageWrapper>
      <Header
        component={
          <div className="header-hero header-hero--pricing bg_cover d-lg-flex align-items-center">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="header-hero-content">
                    {/* <!--====== FIRST SECTION START======--> */}

                    <div className="row justify-content-center">
                      <div className="col-lg-6 col-md-8 col-sm-9">
                        <div className="section-title section-title-2 text-center">
                          <h1 className="title">Plans and Pricing</h1>
                          <br />
                          <p>
                          lexiQA is an enterprise-level Linguistic Quality Assurance solution which is normally available for buyers of language services, LSPs and translation tool vendors. Our pricing plans are based on the volume of processed words and a number of default and on-demand features which reflect the variety of requirements in the translation and localization industry. <span className="lxq-green"><b>We don't count seats; only words.</b></span>
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <!--======FIRST SECTION END======--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        title={siteTitle}
      />
      <SEO title="Linguistic Quality Assurance Pricing Plans - lexiQA" />
      <SEO description="Find out which pricing plan best suits your business needs whether you are a translation buyer, an LSP or a CAT tool vendor" />
      <script type='application/ld+json' dangerouslySetInnerHTML={ { __html: `{ 
                  "@context": "https://schema.org",
                  "@type": "FAQPage",
                  "mainEntity": [{
                      "@type": "Question",
                      "name": "What are processed words and how do you count them?",
                      "acceptedAnswer": {
                          "@type": "Answer",
                          "text": "“Processed words” are the words from your source language content that have been translated into any number of target languages and then checked by lexiQA. For example, if you are translating 1,000 words into 10 languages and we check all your translations, we will have processed 10,000 words. Note that this wordcount is only measured the first time your lexiQA project is created, in other words you can edit your translation as many times as you like and this will not change the count of processed words."
                      }
                  }, {
                      "@type": "Question",
                      "name": "Do you also charge depending on the number of users?",
                      "acceptedAnswer": {
                          "@type": "Answer",
                          "text": "We do not place any restrictions on the number of corporate users, nor do we limit the number of locales that you can use. You also get a different number of features by default depending on your plan."
                      }
                  }, {
                      "@type": "Question",
                      "name": "Do you have a freelancer plan?",
                      "acceptedAnswer": {
                          "@type": "Answer",
                          "text": "Being an enterprise-level solution, lexiQA doesn’t have a plan for freelancers. The lack of personal subscription is based on our belief that freelancers should not pay for software. If you want to use lexiQA as a freelancer, you can check our integrations with free CAT tools, such as MateCat."
                      }
                  }, {
                      "@type": "Question",
                      "name": "How often should I pay?",
                      "acceptedAnswer": {
                          "@type": "Answer",
                          "text": "lexiQA works exclusively on the basis of yearly contracts. You can pay either in monthly instalments or the whole amount of the licence in advance. The decision is yours – we are happy either way!\n"
                      }
                  }, {
                      "@type": "Question",
                      "name": "May I ask you to develop a certain feature?",
                      "acceptedAnswer": {
                          "@type": "Answer",
                          "text": "We’re always open to your suggestions and ideas. If your company requires custom development in order to tailor lexiQA to your workflow needs more precisely, we’ll be more than happy to help."
                      }
                  }, {
                      "@type": "Question",
                      "name": "What about charges for your services?",
                      "acceptedAnswer": {
                          "@type": "Answer",
                          "text": "Following industry standards, we charge an hourly or daily rate for the consultancy work you will need from us. However, that is always determined on a case-by-case basis, depending on the nature and complexity of your requirements. The first hour of consultation is always free, so get in touch!"
                      }
                  }]
      }`}} />
      {/* PRICING PLANS DUO */}
      <section className="bg-white"></section>
      <section className="bg-white pb-50 text-center">

        <div id="pricing-plans" className="row mt-3 d-inline-flex align-items-center flex-column flex-md-row">
          <div className="card-container horizontal">
            <article id="qa-plan-pro" className="card bg-white p-5 d-flex shadow shadow-lg">
                <ul className="uline horizontal">
                  <li className="table-col1 table-item"></li>
                  <li className="table-col2 table-item">LSP Basic</li>
                  <li className="table-col2 table-item">LSP Premium <br/> <span className="small">(minimum consumption applies)</span></li>
                  <li className="table-col2 table-item">Enterprise Basic</li>
                  <li className="table-col2 table-item">Enterprise Premium <br/> <span className="small">(minimum consumption applies)</span></li>
                </ul>
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">Unlimited users</li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">Unlimited API calls</li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">All supported locales</li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">All standard quality checks</li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">All spellcheckers</li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">Monthly stats via API</li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">Check customization</li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>  
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">Named Entity Recognition</li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">Private Server</li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">Private dashboard</li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">Slack/Jira dedicated accounts</li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>  
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">Dedicated account manager</li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>  
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">On-premise installation</li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>  
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">Custom models</li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>                                                                           <ul className="uline horizontal">
                  <li className="table-col1 table-item">24/7 support</li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>   
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">White-labeling</li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>
            </article>
          </div>
        </div>
        <div id="pricing-plan1" className="card-container horizontal row mt-3 align-items-center flex-column flex-md-row">
          <article id="qa-plan-lsp-basic" className="card bg-white fullWidth d-flex shadow shadow-lg">
              <ul className="uline horizontal">
                <li className="table-col1 table-item"></li>
                <li className="table-col2 table-item highlighted">LSP Basic</li>    
              </ul>
              <ul className="uline horizontal">
                <li className="table-col1 table-item">Unlimited users</li>
                <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
              </ul>
              <ul className="uline horizontal">
                <li className="table-col1 table-item">Unlimited API calls</li>
                <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
              </ul>
              <ul className="uline horizontal">
                <li className="table-col1 table-item">All supported locales</li>
                <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
              </ul>
              <ul className="uline horizontal">
                <li className="table-col1 table-item">All standard quality checks</li>
                <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
              </ul>
              <ul className="uline horizontal">
                <li className="table-col1 table-item">All spellcheckers</li>
                <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
              </ul>
              <ul className="uline horizontal">
                <li className="table-col1 table-item">Monthly stats via API</li>
                <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
              </ul>
              <ul className="uline horizontal">
                <li className="table-col1 table-item">Private Server</li>
                <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
              </ul>
              <ul className="uline horizontal">
                <li className="table-col1 table-item">Named Entity Recognition</li>
                <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
              </ul>
              <ul className="uline horizontal">
                <li className="table-col1 table-item">Private dashboard</li>
                <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
              </ul>
              <ul className="uline horizontal">
                <li className="table-col1 table-item">Check customization</li>
                <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
              </ul>  
              <ul className="uline horizontal">
                <li className="table-col1 table-item">Slack/Jira dedicated accounts</li>
                <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
              </ul>  
              <ul className="uline horizontal">
                <li className="table-col1 table-item">Dedicated account manager</li>
                <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
              </ul>  
              <ul className="uline horizontal">
                <li className="table-col1 table-item">On-premise installation</li>
                <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
              </ul>  
              <ul className="uline horizontal">
                <li className="table-col1 table-item">Custom models</li>
                <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
              </ul>                                                                           
              <ul className="uline horizontal">
                <li className="table-col1 table-item">24/7 support</li>
                <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
              </ul>   
              <ul className="uline horizontal">
                <li className="table-col1 table-item">White-labeling</li>
                <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>
              </ul>
            </article>
          </div>
        <div id="pricing-plan2" className="card-container horizontal row mt-3 align-items-center flex-column flex-md-row">
          <article id="qa-plan-lsp-premium" className="card bg-white fullWidth d-flex shadow shadow-lg">
            <ul className="uline horizontal">
              <li className="table-col1 table-item"></li>      
              <li className="table-col2 table-item highlighted">LSP Premium <br/> <span className="small">(minimum consumption applies)</span></li>
            </ul>
            <ul className="uline horizontal">
              <li className="table-col1 table-item">Unlimited users</li>
              <li className="table-col2 table-item"><i className="fa fa-check"></i></li>      
            </ul>
            <ul className="uline horizontal">
              <li className="table-col1 table-item">Unlimited API calls</li>
              <li className="table-col2 table-item"><i className="fa fa-check"></i></li>      
            </ul>
            <ul className="uline horizontal">
              <li className="table-col1 table-item">All supported locales</li>
              <li className="table-col2 table-item"><i className="fa fa-check"></i></li>      
            </ul>
            <ul className="uline horizontal">
              <li className="table-col1 table-item">All standard quality checks</li>
              <li className="table-col2 table-item"><i className="fa fa-check"></i></li>      
            </ul>
            <ul className="uline horizontal">
              <li className="table-col1 table-item">All spellcheckers</li>
              <li className="table-col2 table-item"><i className="fa fa-check"></i></li>      
            </ul>
            <ul className="uline horizontal">
              <li className="table-col1 table-item">Monthly stats via API</li>
              <li className="table-col2 table-item"><i className="fa fa-check"></i></li>      
            </ul>
            <ul className="uline horizontal">
              <li className="table-col1 table-item">Private Server</li>
              <li className="table-col2 table-item"><i className="fa fa-check"></i></li>      
            </ul>
            <ul className="uline horizontal">
              <li className="table-col1 table-item">Named Entity Recognition</li>
              <li className="table-col2 table-item"><i className="fa fa-check"></i></li>      
            </ul>
            <ul className="uline horizontal">
              <li className="table-col1 table-item">Private dashboard</li>
              <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>      
            </ul>
            <ul className="uline horizontal">
              <li className="table-col1 table-item">Check customization</li>
              <li className="table-col2 table-item"><i className="fa fa-check"></i></li>      
            </ul>  
            <ul className="uline horizontal">
              <li className="table-col1 table-item">Slack/Jira dedicated accounts</li>
              <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>      
            </ul>  
            <ul className="uline horizontal">
              <li className="table-col1 table-item">Dedicated account manager</li>
              <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>      
            </ul>  
            <ul className="uline horizontal">
              <li className="table-col1 table-item">On-premise installation</li>
              <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>      
            </ul>  
            <ul className="uline horizontal">
              <li className="table-col1 table-item">Custom models</li>
              <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>      
            </ul>                                                                           
            <ul className="uline horizontal">
              <li className="table-col1 table-item">24/7 support</li>
              <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>      
            </ul>   
            <ul className="uline horizontal">
              <li className="table-col1 table-item">White-labeling</li>
              <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>      
            </ul>
          </article>            
        </div>
        <div id="pricing-plan3" className="card-container horizontal row mt-3 align-items-center flex-column flex-md-row">
          <article id="qa-plan-enterprise-basic" className="card bg-white fullWidth d-flex shadow shadow-lg">
              <ul className="uline horizontal">
                <li className="table-col1 table-item"></li>
                <li className="table-col2 table-item highlighted">Enterprise Basic</li>          
              </ul>
              <ul className="uline horizontal">
                <li className="table-col1 table-item">Unlimited users</li>
                <li className="table-col2 table-item"><i className="fa fa-check"></i></li>          
              </ul>
              <ul className="uline horizontal">
                <li className="table-col1 table-item">Unlimited API calls</li>
                <li className="table-col2 table-item"><i className="fa fa-check"></i></li>          
              </ul>
              <ul className="uline horizontal">
                <li className="table-col1 table-item">All supported locales</li>
                <li className="table-col2 table-item"><i className="fa fa-check"></i></li>          
              </ul>
              <ul className="uline horizontal">
                <li className="table-col1 table-item">All standard quality checks</li>
                <li className="table-col2 table-item"><i className="fa fa-check"></i></li>          
              </ul>
              <ul className="uline horizontal">
                <li className="table-col1 table-item">All spellcheckers</li>
                <li className="table-col2 table-item"><i className="fa fa-check"></i></li>          
              </ul>
              <ul className="uline horizontal">
                <li className="table-col1 table-item">Monthly stats via API</li>
                <li className="table-col2 table-item"><i className="fa fa-check"></i></li>          
              </ul>
              <ul className="uline horizontal">
                <li className="table-col1 table-item">Private Server</li>
                <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>          
              </ul>
              <ul className="uline horizontal">
                <li className="table-col1 table-item">Named Entity Recognition</li>
                <li className="table-col2 table-item"><i className="fa fa-check"></i></li>          
              </ul>
              <ul className="uline horizontal">
                <li className="table-col1 table-item">Private dashboard</li>
                <li className="table-col2 table-item"><i className="fa fa-check"></i></li>          
              </ul>
              <ul className="uline horizontal">
                <li className="table-col1 table-item">Check customization</li>
                <li className="table-col2 table-item"><i className="fa fa-check"></i></li>          
              </ul>  
              <ul className="uline horizontal">
                <li className="table-col1 table-item">Slack/Jira dedicated accounts</li>
                <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>          
              </ul>  
              <ul className="uline horizontal">
                <li className="table-col1 table-item">Dedicated account manager</li>
                <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>          
              </ul>  
              <ul className="uline horizontal">
                <li className="table-col1 table-item">On-premise installation</li>
                <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>          
              </ul>  
              <ul className="uline horizontal">
                <li className="table-col1 table-item">Custom models</li>
                <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>          
              </ul>                                                                           <ul className="uline horizontal">
                <li className="table-col1 table-item">24/7 support</li>
                <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>          
              </ul>   
              <ul className="uline horizontal">
                <li className="table-col1 table-item">White-labeling</li>
                <li className="table-col2 table-item"><i className="fa fa-minus"></i></li>          
              </ul>
          </article>
        </div>
        <div id="pricing-plan4" className="card-container horizontal row mt-3 align-items-center flex-column flex-md-row">
            <article id="qa-plan-enterprise-premium" className="card bg-white fullWidth d-flex shadow shadow-lg">
                <ul className="uline horizontal">
                  <li className="table-col1 table-item"></li>
                  <li className="table-col2 table-item highlighted">Enterprise Premium <br/> <span className="small">(minimum consumption applies)</span></li>
                </ul>
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">Unlimited users</li>          
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">Unlimited API calls</li>          
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">All supported locales</li>          
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">All standard quality checks</li>          
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">All spellcheckers</li>          
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">Monthly stats via API</li>          
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">Private Server</li>          
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">Named Entity Recognition</li>          
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">Private dashboard</li>          
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">Check customization</li>          
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>  
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">Slack/Jira dedicated accounts</li>          
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>  
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">Dedicated account manager</li>          
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>  
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">On-premise installation</li>          
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>  
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">Custom models</li>          
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>                                                                           
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">24/7 support</li>          
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>   
                <ul className="uline horizontal">
                  <li className="table-col1 table-item">White-labeling</li>          
                  <li className="table-col2 table-item"><i className="fa fa-check"></i></li>
                </ul>
            </article>
          </div>          
      </section>

      {/* <!--====== SERVICE PART ENDS ======--> */}

      {/* <!--====== FAQ (DARK) PART START ======--> */}
      <section className="faq-area pt-70 pb-70 bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 pb-5">
              <div className="faq-content mt-45">
                <div className="section-title section-title-6">
                  {/* <h6 className="sub-title">FAQ</h6> */}
                  <h4 className="title text-center">Frequently Asked Questions</h4>
                </div>
                <div className="faq-accordion faq-accordion-6">
                  <div className="accordion" id="accordionExample">
                    {PAGEDATA.faq.map((qa, idx) => {
                      return (
                        <Faq
                          data={qa}
                          idx={idx}
                          key={qa.question}
                          isOpen={idx === 0 ? true : false}
                        />
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--====== FAQ (DARK) PART ENDS ======--> */}

      {/* <!--====== FAQ PART START [ D I S A B L E D ] ======--> */}
      {/*
      <section className="faq-area pt-70 bg-light pb-70">
          <div className="container">
              <div className="row">
                  <div className="col-lg-8 offset-lg-2 pb-5">
                      <div className="faq-content mt-45">
                          <div className="section-title section-title-2">
                              <h4 className="title text-center">Frequently Asked Questions</h4>
                          </div>
                          <div className="faq-accordion">
                              <div className="accordion" id="accordionExample">
                                {
                                  faq.map( 
                                    ( qa, idx ) => {
                                      return <Faq data={qa} idx={idx} key={qa.question}/>
                                    }
                                  )
                                }
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      */}
      {/* <!--====== FAQ PART ENDS ======--> */}

      {/* <!--====== MORE QUESTIONS? ======--> */}
      <section className="call-to-action-area bg-white pt-115 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="call-action">
                <div className="section-title text-center section-title-white">
                  <h5 className="title mb-3">
                    <span>More questions?</span>
                  </h5>
                  <h6 className="sub-title mb-5">
                  If we haven&apos;t answered all your questions, feel free to drop us a line any time.
                  </h6>
                </div>

                <div className="text-center">
                  <Link className="main-btn btn-primary" to={"/contact/"}>
                    Check in now
                  </Link>
                </div>
              </div>
              {/* <!-- call action --> */}
            </div>
          </div>
          {/* <!-- row --> */}
        </div>
        {/* <!-- container --> */}
      </section>
      {/* <!--====== MORE QUESTIONS? ======--> */}

      <Footer />
    </PageWrapper>
  )
}

export default PricingIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
